import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default () => {

    const textContent = document.querySelectorAll('.gutenberg-who-we-are-block .text-content .anim');

    gsap.set(textContent, {x: -100, opacity: 0})

    ScrollTrigger.batch(textContent, {
        onEnter: batch => gsap.to(batch, {
            stagger: 0.1,
            delay: 0.3,
            x: 0,
            opacity: 1,
            start: 'top center'
        })
    })

}