window.$ = window.jQuery = require('jquery');
window.jQuery.fn.load = function(callback) {
    $(window).on('load', callback);
};


import lazysizes from 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';

import { homepageSlider, testimonialSlider, gallerySlider } from './slider';
import Header from './header';

import threeColGrid from './gutenberg/three-col-grid';
import whoWeAre from './gutenberg/who-we-are';
import featuredPage from './gutenberg/featured-page';
import Accordion from './gutenberg/faq-block';
import Menu from './menu';
require('./lightbox');

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.requireJs = function(modules, cb){
	window.require(modules, cb);
};

require('./gutenberg/scroll-pin');

class App {
    static start() {
        return new App()
    }

    constructor() {
        Promise
            .all([
                App.domReady(),
            ])
            .then(this.init.bind(this))
    }

    static domReady() {
        lazysizes.init();
    }

    static showPage() {

        Header();
        
        Menu();

        //Slider
        homepageSlider('.homepage-slider .swiper');
        testimonialSlider('.testimonials .swiper');
        gallerySlider('.gallery-container .swiper');

        //Animation
        threeColGrid();
        whoWeAre();
        featuredPage();
        Accordion();

    }

    init() {
        App.showPage();
        ;
    }
}

App.start()