export default () => {
    window.addEventListener('scroll', function() {
        const scroll =  window.pageYOffset || document.documentElement.scrollTop;
        const header = document.querySelector('header.header');

        if(scroll > 0) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    });
}