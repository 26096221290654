import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default () => {

    const col = document.querySelectorAll('.gutenberg-three-column-grid .three-column-grid-container .column-grid');

    gsap.set(col, {y: 100, opacity: 0})

    ScrollTrigger.batch(col, {
        onEnter: batch => gsap.to(batch, {
            stagger: 0.1,
            delay: 0.3,
            y: 0,
            opacity: 1,
            start: 'top center'
        })
    })

}