import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
Swiper.use([Navigation,Pagination,EffectFade]);

const homepageSlider = (target) => {
    new Swiper(target, {
        modules: [Autoplay],
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })
}

const testimonialSlider = (target) => {
    new Swiper(target, {
        modules: [Autoplay],
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        autoplay: true,
    })
}

const gallerySlider = (target) => {
    new Swiper(target, {
        slidesPerView: 1.5,
        spaceBetween: 20,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                spaceBetween: 20
            }
        }
    })
}

export {homepageSlider, testimonialSlider, gallerySlider}