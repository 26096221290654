import { TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import ScrollMagic from 'scrollmagic';
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

$(document).ready(function(){
    const sp = $('.gutenberg-scroll-pin');

    sp.each(function(sp_count) {
        $(this).addClass('s-pin'+sp_count);

        if($('.s-pin'+sp_count).length>0) {
            let controller = new ScrollMagic.Controller();

            var duration = ($('.s-pin'+sp_count+' .container-slide .slide').length-1) * 100;
            var scrollHome = new ScrollMagic.Scene({
                triggerElement: '.s-pin'+sp_count+' .bg-slide',
                triggerHook: 'onLeave',
                duration: duration+'%'
            })
                .setPin('.s-pin'+sp_count+' .bg-slide')
                .addTo(controller);

            $('.gutenberg-scroll-pin .container-slide .slide').each(function(n, elem){
                var tl = new TimelineMax();
                tl
                    .fromTo($('.gutenberg-scroll-pin .slide-background').eq(n), {opacity: 0}, {opacity: 1, duration: 0.5});

                new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.5,
                    duration: "15%"
                })
                    .setTween(tl)
                    .addTo(controller);
            })
        }
    })
})