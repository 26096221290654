import {gsap} from "gsap";

export default () => {

    $('.menu-icon').on('click', function() {
        if($(this).hasClass('open')) {
            let tl = gsap.timeline()
            tl
                .to('.menu-icon', {duration: 0.3, autoAlpha: 0})
                .to('.mega-menu', {duration: 0.3, autoAlpha: 0})
                .to('.mega-menu-container', {duration: 0.3, x: '-100%'})
                .call(function(){$('.sub-menu').removeClass('submenu-active')})
                .call(function(){$('.menu-icon').removeClass('open')})
                .to('.menu-icon', {duration: 0.3, autoAlpha: 1})
                .call(function(){$('header.header').removeClass('small-header')})
        } else {
            let tl = gsap.timeline()
            tl
                .call(function(){$('header.header').addClass('small-header')})
                .to('.menu-icon', {duration: 0.3, autoAlpha: 0})
                .to('.mega-menu-container', {duration: 0.3, x: 0})
                .to('.mega-menu', {duration: 0.3, autoAlpha: 1})
                .call(function(){$('.menu-icon').addClass('open')})
                .to('.menu-icon', {duration: 0.3, autoAlpha: 1})
        }
    })
    

    $('.mega-menu li.menu-item .open-submenu').on('click', function(){
        $('.sub-menu').removeClass('submenu-active') 
        $(this).siblings('.sub-menu').addClass('submenu-active');
    })

    $('.mega-menu .back-menu').on('click', function(){
        $('.sub-menu').removeClass('submenu-active') 
    })

}